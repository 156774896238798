/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useDeleteCourierCutOffTime,
  useGetCourierCutoffTable,
  useGetWarehouseTable,
} from 'framework/api/methods'

import { Button, PopUpAlert } from 'components/atoms'
import {
  AddCourierCutOffTime,
  UpdateCourierCutOffTime,
} from 'components/DrawerModals/CourierCutOffTime'

import { DataTable } from './CourierCutOffTimeDataTable'

import 'react-toastify/dist/ReactToastify.css'

export interface CourierCutOffTimeProps {
  [x: string]: any
}

export const CourierCutOffTime: FC<CourierCutOffTimeProps> = ({
  className,
}: CourierCutOffTimeProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const cutOffTimeEditAccess = userDetails?.userPermissions?.includes(
    'CutOffTimeEditAccess',
  )
  const cutOffTimeDeleteAccess = userDetails?.userPermissions?.includes(
    'CutOffTimeDeleteAccess',
  )
  const [searchWareHouseName, setSearchWareHouseName] = useState('')
  const [rows, setRows] = useState({})
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [deleteCutOffTimePopOutIsOpen, setDeleteCutOffTimePopOutIsOpen] =
    useState(false)
  const [addCutOffTimeDrawerIsOpen, setAddCutOffTimeDrawerIsOpen] =
    useState(false)
  const [updateCutOffTimeDrawerIsOpen, setUpdateCutOffTimeDrawerIsOpen] =
    useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    name: '',
  })
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })

  const [wareHouseList, setWareHouseList] = useState([])

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** API Call for get cut off time list using react-query */
  const {
    mutate: getCutOffTimeListMutate,
    isLoading: getCutOffTimeListIsLoading,
  } = useGetCourierCutoffTable()

  /** Process the get cut off time table data */
  async function getCutOffTimeList() {
    getCutOffTimeListMutate(tableVariables, {
      onSuccess: ({ data: successData }: any) => {
        setRows(successData?.cutoffTime)
        setTotalRowCount(successData?.totalCount)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API for Delete cutoff time */
  const { mutate: deleteCutoffTimeMutate } = useDeleteCourierCutOffTime()

  /** Process the Delete cut off time */
  async function deleteCutOffTime(cutoffTimeID: string) {
    deleteCutoffTimeMutate(
      { cutoffTimeID },
      {
        onSuccess: ({ data: successData }: any) => {
          setDeleteCutOffTimePopOutIsOpen(false)
          notify(successData.message, 'success')
          getCutOffTimeList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          setDeleteCutOffTimePopOutIsOpen(false)
        },
      },
    )
  }

  const { mutate: getWarehouseListMutate } = useGetWarehouseTable()

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { take: 100, skip: 0, name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedWarehouseList = successData.warehouses.map(
            (item: any) => {
              return {
                ...item,
                value: item?.id,
                label: item?.code + ' - ' + item?.name,
              }
            },
          )
          setWareHouseList(updatedWarehouseList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** call warehouses list when table variables change */
  useEffect(() => {
    getCutOffTimeList()
  }, [tableVariables])

  useEffect(() => {
    getWarehouseList()
  }, [])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        name: searchWareHouseName,
      })
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>Courier Cut-off Time</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            <div className='w-1/2'>
              {cutOffTimeEditAccess && (
                <Button
                  onClick={() => {
                    setAddCutOffTimeDrawerIsOpen(true)
                  }}>
                  <i className='ri-add-circle-line pr-2'></i>Add Cut-off Time
                </Button>
              )}
            </div>
            <div className='flex flex-col w-[432px] pl-2 relative'>
              <input
                onChange={(e: any) => {
                  setSearchWareHouseName(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by warehouse or carrier'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
              />
              <i
                className='ri-search-2-line absolute top-0 bottom-0 flex items-center left-[22px] text-1xl text-N-500 cursor-pointer'
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    name: searchWareHouseName,
                  })
                }}></i>
            </div>
          </div>
          <div className='w-full h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setUpdateCutOffTimeDrawerIsOpen={setUpdateCutOffTimeDrawerIsOpen}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getCutOffTimeListIsLoading={getCutOffTimeListIsLoading}
              setDeleteCutOffTimePopOutIsOpen={setDeleteCutOffTimePopOutIsOpen}
              cutOffTimeDeleteAccess={cutOffTimeDeleteAccess}
            />
          </div>

          {cutOffTimeEditAccess && addCutOffTimeDrawerIsOpen && (
            <AddCourierCutOffTime
              isActive={addCutOffTimeDrawerIsOpen}
              onCloseClick={() => setAddCutOffTimeDrawerIsOpen(false)}
              notify={notify}
              wareHouseList={wareHouseList}
              getCutOffTimeList={getCutOffTimeList}
            />
          )}

          {cutOffTimeEditAccess && updateCutOffTimeDrawerIsOpen && (
            <UpdateCourierCutOffTime
              isActive={updateCutOffTimeDrawerIsOpen}
              onCloseClick={() => setUpdateCutOffTimeDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              notify={notify}
              wareHouseList={wareHouseList}
              getCutOffTimeList={getCutOffTimeList}
            />
          )}

          {cutOffTimeDeleteAccess && deleteCutOffTimePopOutIsOpen && (
            <PopUpAlert
              isActive={deleteCutOffTimePopOutIsOpen}
              title='Delete Cut-off Time?'
              message='Are you sure you want to proceed? This is not reversible.'
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => setDeleteCutOffTimePopOutIsOpen(false)}
              onOverlayClick={() => setDeleteCutOffTimePopOutIsOpen(false)}
              onConfirmClick={() => {
                deleteCutOffTime(selectedRowByButtonAction.id)
              }}
            />
          )}

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

CourierCutOffTime.defaultProps = {}

export default CourierCutOffTime
