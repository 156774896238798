/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useGetFileVsOrderList,
  useGetPartnerTable,
} from 'framework/api/methods'

import { AdvanceSearchModal } from './AdvanceSearchModal'
import { DataTable } from './FileVsOrdersDataTable'

export interface FileVsOrdersProps {
  [x: string]: any
}

export const FileVsOrders: FC<FileVsOrdersProps> = ({
  className,
  ...restProps
}: FileVsOrdersProps) => {
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [
    fileVsOrderAdvancedSearchModalIsOpen,
    setFileVsOrderAdvancedSearchModalIsOpen,
  ] = useState(false)
  const [rows, setRows] = useState({})
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    search: '',
  })
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pageNumber, setPageNumber] = useState(1)

  const [totalRowCount, setTotalRowCount] = useState(0)
  const [filterVariables, setFilterVariables] = useState<any>({
    principle: { label: 'All', value: 'All' },
    from: '',
    to: '',
  })

  const [principleList, setPrincipleList] = useState([])

  /** API Call for partner list using react-query */
  const { mutate: getPartnerListMutate } = useGetPartnerTable()
  /** Process the get partner list */
  async function getPartnerList() {
    getPartnerListMutate(
      { take: 100, skip: 0, Search: '' },
      {
        onSuccess: ({ data: successData }: any) => {
          const partners = successData?.partners
            ?.filter((principle: any) =>
              principle?.partnerOrderIntegrations?.some(
                (integration: any) => integration?.name === 'AzureSftp',
              ),
            )
            ?.map((principle: any) => {
              return {
                value: principle.code,
                label: principle.name,
              }
            })
          setPrincipleList(partners)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData?.message || 'Error...', 'error')
        },
      },
    )
  }
  /** API Call for get list using react-query */
  const {
    mutate: getFileVsOrderListMutate,
    isLoading: isGetFileVsOrderListIsLoading,
  } = useGetFileVsOrderList()

  /** Process the get table data */
  async function getFileVsOrderList(
    take?: number,
    skip?: number,
    search?: string,
    principle?: any,
    from?: any,
    to?: any,
  ) {
    getFileVsOrderListMutate(
      {
        take,
        skip,
        search,
        principle,
        from: from
          ? from?.getFullYear() +
            '-' +
            ('0' + (from.getMonth() + 1)).slice(-2) +
            '-' +
            from.getDate()
          : '',
        to: to
          ? to.getFullYear() +
            '-' +
            ('0' + (to.getMonth() + 1)).slice(-2) +
            '-' +
            to.getDate()
          : '',
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.orderResponses || [])
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }
  useEffect(() => {
    getFileVsOrderList(
      tableVariables.take,
      tableVariables.skip,
      tableVariables.search,
      filterVariables.principle,
      filterVariables.from,
      filterVariables.to,
    )
  }, [tableVariables])

  useEffect(() => {
    getPartnerList()
  }, [])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        search: searchKeyword,
      })
      setPageNumber(1)
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}
      {...restProps}>
      <div className='flex flex-col w-full h-full px-5 bg-white rounded-lg shadow-md'>
        <div className='flex items-center w-full mt-6'>
          <div className='flex flex-col w-1/3 relative'>
            <input
              onChange={(e: any) => {
                setSearchKeyword(e.target.value)
              }}
              onKeyPress={(e: any) => {
                handleSearchKeyPress(e)
              }}
              type='text'
              placeholder='Search by Principle, WMS orders, Customer ref'
              className='pr-3 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
            />
            <i
              className='ri-search-2-line absolute top-[6px] left-[15px] text-lg text-N-500 cursor-pointer'
              onClick={() => {
                setTableVariables({
                  ...tableVariables,
                  search: searchKeyword,
                })
                setPageNumber(1)
              }}></i>
          </div>
          <button
            onClick={() => {
              setFileVsOrderAdvancedSearchModalIsOpen(true)
            }}
            className='flex items-center justify-center w-9 h-9 bg-white border border-N-200 rounded ml-2 relative'>
            <i className='ri-filter-2-line text-N-500'></i>
            {(filterVariables.principle.label !== 'All' ||
              filterVariables.from !== '' ||
              filterVariables.to !== '') && (
              <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-3px] right-[-3px]'></div>
            )}
          </button>
        </div>

        <div className='w-full h-[calc(100%-110px)] pt-4'>
          <DataTable
            itemData={rows || []}
            setTableVariables={setTableVariables}
            tableVariables={tableVariables}
            totalRowCount={totalRowCount}
            isLoading={isGetFileVsOrderListIsLoading}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {fileVsOrderAdvancedSearchModalIsOpen && (
        <AdvanceSearchModal
          setFilterVariables={setFilterVariables}
          filterVariables={filterVariables}
          isActive={true}
          onCloseClick={() => {
            setFileVsOrderAdvancedSearchModalIsOpen(false)
          }}
          searchKeyword={searchKeyword}
          setPageNumber={setPageNumber}
          principleList={principleList}
          getFileVsOrderList={getFileVsOrderList}
          tableVariables={tableVariables}
        />
      )}

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

FileVsOrders.defaultProps = {}

export default FileVsOrders
