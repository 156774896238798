/* eslint-disable prettier/prettier */
import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GetCarrierProductListInterface {
  countryCode?: string
}

export const useGetCarrierProductList = () => {
    const mutate = useMutation(({ countryCode }: GetCarrierProductListInterface) => {
        return postAPI(`/CarrierProductList`, { countryCode: countryCode })
      })      
    return mutate
}

export default useGetCarrierProductList
