import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateOrderReleasedInterface {
  partnerOrderId?: string
}

export const useCreateOrderReleased = () => {
  const mutate = useMutation(
    ({ partnerOrderId }: CreateOrderReleasedInterface) => {
      return postAPI(`/OrderReleased`, {
        partnerOrderId,
      })
    },
  )

  return mutate
}

export default useCreateOrderReleased
