/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'

export interface SelectCourierServiceModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  notify?: any
  shipmentPackageDetails?: any
  setShipmentPackageDetails?: any
  useCreateShipmentPackage?: any
  callOrderShipmentsById?: any
  selectedShipmentId?: any
  handleSelectCourierServiceConfirmClick?: any
  selectedOrderDetails?: any
  carrierList?: any
}

export const SelectCourierServiceModal: FC<SelectCourierServiceModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  shipmentPackageDetails,
  setShipmentPackageDetails,
  useCreateShipmentPackage,
  callOrderShipmentsById,
  selectedShipmentId,
  handleSelectCourierServiceConfirmClick,
  notify,
  selectedOrderDetails,
  carrierList,
}: SelectCourierServiceModalProps) => {
  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  /**Carriers for quotations */
  const [carriersList, setCarriersList] = useState<any>([])
  const [tempCarrierList, setTempCarrierList] = useState<any>([])
  const [selectedStatus, setSelectedStatus] = useState<any>(carriersList)
  const [containsPOBoxOrParcelLocker, setContainsPOBoxOrParcelLocker] =
    useState(false)

  useEffect(() => {
    let itemId = 0
    const uniqueCarriersMap = new Map()
    const transformedData = carrierList?.reduce(
      (result: any[], product: { carrier: any }) => {
        if (!uniqueCarriersMap?.has(product?.carrier)) {
          uniqueCarriersMap?.set(product?.carrier, product)
          itemId++
          if (containsPOBoxOrParcelLocker) {
            if (
              product?.carrier === 'AUSPOST' ||
              product?.carrier === 'AUSPOST_INTERNATIONAL'
            ) {
              result.push({
                itemId: itemId,
                selected: false,
                value: product?.carrier,
                label: product?.carrier,
                ...product, // Include all properties from the original product
              })
            }
          } else {
            result.push({
              itemId: itemId,
              selected: false,
              value: product?.carrier,
              label: product?.carrier,
              ...product, // Include all properties from the original product
            })
          }
        }
        return result
      },
      [],
    )
    if (!containsPOBoxOrParcelLocker) {
      transformedData.push({
        itemId: itemId + 1,
        selected: false,
        value: 'All Services',
        label: 'All Services',
        carrierCode: null,
        carrierName: null,
        isActive: true,
        isDisabled: false,
      })
    }
    setTempCarrierList(transformedData)
  }, [containsPOBoxOrParcelLocker])

  useEffect(() => {
    const newList = tempCarrierList.map((item: any) => {
      if (item.value === selectedStatus) {
        return { ...item, selected: true, isDisabled: false }
      }

      return {
        ...item,
        isActive: true,
        isDisabled: false,
      }
    })
    setCarriersList(newList)
  }, [tempCarrierList, selectedStatus, containsPOBoxOrParcelLocker])

  const handleConfirmClick = (event: any) => {
    event.stopPropagation()
    handleSelectCourierServiceConfirmClick(
      selectedStatus === 'AlliedExpress' ? 'ALLIED' : selectedStatus,
    )
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  useEffect(() => {
    if (
      isActive &&
      selectedOrderDetails?.shipments?.length > 0 &&
      selectedOrderDetails?.shipments[0]?.defaultCarrierCode &&
      selectedOrderDetails?.shipments[0]?.defaultCarrierName
    ) {
      if (selectedOrderDetails?.shipments[0]?.defaultCarrierCode === 'ALL') {
        setSelectedStatus('All Services')
      } else if (
        selectedOrderDetails?.shipments[0]?.defaultCarrierCode === 'Smart/Send'
      ) {
        setSelectedStatus('SMART/SEND')
      } else {
        setSelectedStatus(
          selectedOrderDetails?.shipments[0]?.defaultCarrierCode,
        )
      }
    }
  }, [selectedOrderDetails, isActive])

  const containsKeyword = (value: any, keyword: any) => {
    return value?.toLowerCase()?.includes(keyword?.toLowerCase())
  }

  useEffect(() => {
    const address: any = selectedOrderDetails?.partnerOrder?.shippingAddress

    for (const key in address) {
      // eslint-disable-next-line no-prototype-builtins
      if (address?.hasOwnProperty(key)) {
        const value = address[key]
        if (
          containsKeyword(value, 'PO Box') ||
          containsKeyword(value, 'Parcel Locker')
        ) {
          setContainsPOBoxOrParcelLocker(true)
          break
        }
      }
    }
  }, [selectedOrderDetails])

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[472px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Select Courier Service to Quote</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        {/* Modal Body  */}
        <div className='flex flex-col w-full pt-6 pl-4 pr-36'>
          {carriersList.map((item: any) => (
            <div
              key={item.itemId}
              className={CN(
                'flex items-center px-[13px] py-2 w-full rounded-lg mr-2 cursor-pointer mb-6',
                {
                  'bg-P-500': item.selected,
                  'border border-N-500': !item.selected,
                },
                {
                  'opacity-50 !cursor-not-allowed': !item.isActive,
                },
              )}
              onClick={() => {
                setSelectedStatus(item.value)
              }}>
              <input
                type='radio'
                name={item.label}
                id={item.itemId}
                checked={item.selected}
                readOnly
                className='appearance-none rounded-full h-3 w-3 border border-N-500 bg-white checked:bg-P-500 checked:border-white checked:border-2 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
              />
              <p
                className={CN(
                  'pl-2 font-semibold min-w-[60px] cursor-pointer',
                  {
                    'text-white': item.selected,
                    'text-N-500': !item.selected,
                  },
                )}>
                {item.label}
              </p>
            </div>
          ))}
        </div>
        {/* Modal Footer  */}
        <div className='w-full border-t border-t-N-200 mt-2 px-5 py-4'>
          <Button
            onClick={(e: any) => {
              handleConfirmClick(e)
            }}
            disabled={selectedStatus.length < 1 ? true : false}
            isLoading={false}>
            Quote
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

SelectCourierServiceModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default SelectCourierServiceModal
