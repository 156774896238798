/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetLabelListInterface {
  take?: any
  skip?: any
  category?: string
}
export const useGetLabelList = () => {
  const mutate = useMutation(
    ({ category, take, skip }: GetLabelListInterface) => {
      return get(`Labels?category=${category}&take=${take}&skip=${skip}`)
    },
  )

  return mutate
}

export default useGetLabelList
