/* Layouts */
import { AuthLayout } from 'components/layouts/AuthLayout'
import { MainLayout } from 'components/layouts/MainLayout'
/* Page components */
import { Login } from 'components/pages/auth/Login'
import { BulkOrderConsignment } from 'components/pages/BulkOrderConsignment'
import { CourierCutOffTime } from 'components/pages/configurations/CourierCutOffTime'
import { OrderCutOffTime } from 'components/pages/configurations/OrderCutOffTime'
import { Dashboard } from 'components/pages/Dashboard'
import { Error404 } from 'components/pages/Error404'
import { OrderConsignment } from 'components/pages/OrderConsignment'
import { Orders } from 'components/pages/Orders'
import { Partners } from 'components/pages/Partners'
import { Reports } from 'components/pages/Reports/Reports'
import { StarshipItManifesting } from 'components/pages/StarshipItManifesting'
import { FileVsOrders } from 'components/pages/stocks/FileVsOrders'
import { StockOrder } from 'components/pages/stocks/StockOrder'
import { StockOnHand } from 'components/pages/stocks/StocksOnHand'
import { UserList } from 'components/pages/users/UserList'
import { UserPrivilegesList } from 'components/pages/users/UserPrivilegesList'
import { UserRoleList } from 'components/pages/users/UserRoleList'
import { Warehouse } from 'components/pages/Warehouse'
import { AgencyTaskCreation } from 'components/pages/warehouseAllocation/AgencyTaskCreation'
import { GenericTaskCreation } from 'components/pages/warehouseAllocation/GenericTaskCreation'

interface Route {
  path: string
  component?: any
  layout?: any
  redirectTo?: string
  isSecured?: boolean
  isAuthScreen?: boolean
}

export const routes: Route[] = [
  {
    path: '/auth',
    redirectTo: '/auth/login',
  },
  {
    path: '/auth/login',
    component: Login,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/auth/reset-password',
    component: Login,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/confirm/email',
    component: Login,
    layout: AuthLayout,
    isAuthScreen: true,
  },
  {
    path: '/',
    component: Dashboard,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: 'order-consignment/single-order-consignment',
    component: OrderConsignment,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: 'order-consignment/bulk-order-consignment',
    component: BulkOrderConsignment,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/partners',
    component: Partners,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/orders',
    component: Orders,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/users/user-List',
    component: UserList,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/users/user-roles',
    component: UserRoleList,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/users/user-privileges',
    component: UserPrivilegesList,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/warehouse',
    component: Warehouse,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/configuration/courier-cutoff-time',
    component: CourierCutOffTime,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/configuration/order-cutoff-time',
    component: OrderCutOffTime,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/reports',
    component: Reports,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: 'order-consignment/starshipit-manifesting',
    component: StarshipItManifesting,
    layout: MainLayout,
    isSecured: true,
  },

  {
    path: '/stocks/stock-orders',
    component: StockOrder,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/stocks/stock-on-hand',
    component: StockOnHand,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/stocks/file-vs-orders',
    component: FileVsOrders,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/warehouse-allocation/generic-task-creation',
    component: GenericTaskCreation,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '/warehouse-allocation/agency-task-creation',
    component: AgencyTaskCreation,
    layout: MainLayout,
    isSecured: true,
  },
  {
    path: '*',
    component: Error404,
    isSecured: true,
  },
]

export default routes
