/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'
import starshipitIcon from 'assets/images/starshipitIcon.svg'
import CN from 'classnames'
import { useExtractLocation, useRegenerateKey } from 'framework/api/methods'

import { PopUpAlert } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'

export interface IntegrationPanelProps {
  IntegrationData?: any
  [x: string]: any
}

export const IntegrationPanel: FC<IntegrationPanelProps> = ({
  IntegrationData,
  setNewIntegrations,
  indexValue,
  newIntegrations,
  setDeletePartnerIntegrationPopOutIsOpen,
  setSelectedIndexOfIntegration,
  wareHouseList,
  selectedRowByButtonAction,
  notify,
  platformList,
}: IntegrationPanelProps) => {
  const [integrationPanelData, setIntegrationPanelData] =
    useState(IntegrationData)
  const [showDetails, setShowDetails] = useState(true)
  const [showApiPassword, setShowApiPassword] = useState(false)
  const [showConsumerSecret, setShowConsumerSecret] = useState(false)
  const [isDisableExtractLocation, setIsDisableExtractLocation] = useState(true)
  const [pushApiKey, setPushApiKey] = useState(integrationPanelData.apiKey)
  const [
    regenerateKeyConfirmationModalOpen,
    setRegenerateKeyConfirmationModalOpen,
  ] = useState(false)
  const [wmsIntegration, setWmsIntegration] = useState(
    wareHouseList[0]?.warehouseIntegration,
  )

  useEffect(() => {
    const toBeAdded = newIntegrations
    if (integrationPanelData.type === 'existing') {
      toBeAdded[indexValue] = integrationPanelData
      setNewIntegrations(toBeAdded.slice())
    }

    if (integrationPanelData.type === 'new') {
      toBeAdded[indexValue] = integrationPanelData
      setNewIntegrations(toBeAdded.slice())
    }

    /** Checking API Base URL & API Password for Shopify to Extract the location List */
    if (
      integrationPanelData.apiBaseUrl &&
      integrationPanelData.apiBaseUrl.length > 0 &&
      integrationPanelData.apiPassword &&
      integrationPanelData.apiPassword.length > 0 &&
      integrationPanelData.id &&
      integrationPanelData.id.length > 0
    ) {
      setIsDisableExtractLocation(false)
    } else {
      setIsDisableExtractLocation(true)
    }
  }, [integrationPanelData, pushApiKey])

  // /** Process the Extract Location Submit */
  const extractLocationOnSuccess = (data: any) => {}
  const extractLocationOnError = (error: any) => {
    notify('Failed..! Please check URL & Password', 'error')
  }
  const {
    isLoading: extractLocationIsLoading,
    data: extractLocationData,
    refetch: callExtractLocation,
  } = useExtractLocation(
    {
      partnerId: selectedRowByButtonAction.id,
      partnerIntegrationId: integrationPanelData.id,
      apiBaseUrl: integrationPanelData.apiBaseUrl,
      apiPassword: integrationPanelData.apiPassword,
    },
    extractLocationOnSuccess,
    extractLocationOnError,
  )

  /** Process the Re Generate Key */
  const { mutate: regenrateKeyMutate, isLoading: updateIntegrationIsLoading } =
    useRegenerateKey()

  const regenerateKey = () => {
    regenrateKeyMutate(
      {
        Id: selectedRowByButtonAction.id,
        PId: integrationPanelData.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setPushApiKey(successData.integrationPushAPI.apiKey)
          setRegenerateKeyConfirmationModalOpen(false)
          notify('PushAPI key regenerated', 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  function checkWMSIntegration(warehouseIntegration?: any) {
    setWmsIntegration(warehouseIntegration)
    if (warehouseIntegration?.name != 'IcsWMS') {
      notify(
        'Stock validation will not be performed for Paperless WMS',
        'error',
      )
    }
  }

  return (
    <>
      <div
        className={CN(
          'flex w-full bg-N-50 mt-4 items-center justify-between p-4 border-b border-N-200 rounded-t-lg',
          {
            'rounded-b-lg': !showDetails,
          },
        )}>
        <div className='flex items-center'>
          {integrationPanelData.name === 'Shopify' && (
            <img src={IconShopify} alt='IconShopify' />
          )}
          {integrationPanelData.name === 'Woocommerce' && (
            <img src={Iconwoocommerce} alt='Iconwoocommerce' />
          )}
          {integrationPanelData.name === 'PushApi' && (
            <img src={IconPushAPI} alt='IconPushAPI' />
          )}
          {integrationPanelData.name === 'StarshipIT' && (
            <img src={starshipitIcon} alt='IconStarshipit' />
          )}
          <p className='text-lg font-bold pl-2 pr-2'>
            {integrationPanelData.name}
          </p>
          <div className='bg-N-300 rounded-[100px]'>
            <p className='text-xs font-semibold px-[7px]'>
              {integrationPanelData.version}
            </p>
          </div>
        </div>
        <div className='flex items-center'>
          <ToggleSwitch
            text={integrationPanelData.isActive ? 'Enabled' : 'Disabled'}
            setValue={integrationPanelData.isActive}
            appearance='mini'
            onToggleClick={(e: any) => {
              setIntegrationPanelData({
                ...integrationPanelData,
                isActive: e.target.checked,
              })
            }}
          />
          <div className='flex items-center justify-center w-7 h-7 bg-R-50 ml-6 mr-7 cursor-pointer'>
            <i
              onClick={() => {
                setDeletePartnerIntegrationPopOutIsOpen(true)
                setSelectedIndexOfIntegration(indexValue)
              }}
              className='ri-delete-bin-6-line text-R-500'></i>
          </div>
          <div
            className='flex items-center justify-center cursor-pointer'
            onClick={() => {
              setShowDetails(!showDetails)
            }}>
            <i
              className={CN('', {
                'ri-arrow-up-s-line': showDetails,
                'ri-arrow-down-s-line': !showDetails,
              })}></i>
          </div>
        </div>
      </div>
      {showDetails && (
        <div
          className={CN(
            'flex flex-col w-full bg-N-50 items-start justify-center p-4 border-b border-N-200 transition-height rounded-b-lg',
            {},
          )}>
          <div className='flex w-full items-center pt-6'>
            <ToggleSwitch
              text='Extract Orders'
              className='mr-8'
              setValue={integrationPanelData.extractOrders}
              appearance='default'
              onToggleClick={(e: any) => {
                setIntegrationPanelData({
                  ...integrationPanelData,
                  extractOrders: e.target.checked,
                })
              }}
            />
            <ToggleSwitch
              text='Update Tracking Info'
              className='mr-8'
              setValue={integrationPanelData.updateTrackingInfo}
              appearance='default'
              onToggleClick={(e: any) => {
                setIntegrationPanelData({
                  ...integrationPanelData,
                  updateTrackingInfo: e.target.checked,
                })
              }}
            />
            <ToggleSwitch
              text='Update Shipping Info'
              className='mr-8'
              setValue={integrationPanelData.updateShippingInfo}
              appearance='default'
              onToggleClick={(e: any) => {
                setIntegrationPanelData({
                  ...integrationPanelData,
                  updateShippingInfo: e.target.checked,
                })
              }}
            />
          </div>
          <div className='flex w-full items-center pt-6'>
            <ToggleSwitch
              text='Stock Validation'
              className='mr-8'
              setValue={integrationPanelData.isStockValidating}
              appearance='default'
              isDisabled={
                integrationPanelData?.wareHouse?.label
                  ?.toLowerCase()
                  .includes('paperless') ||
                integrationPanelData?.wareHouse === undefined
                  ? true
                  : false
              }
              onToggleClick={(e: any) => {
                setIntegrationPanelData({
                  ...integrationPanelData,
                  isStockValidating: e.target.checked,
                })
              }}
            />
          </div>

          {integrationPanelData.platform !== undefined && (
            <div className='flex flex-col w-full pt-6'>
              <div className='flex'>
                <span className='text-N-700'>Platform</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>

              <SimpleSelect
                options={platformList}
                value={integrationPanelData.platform}
                onChange={(e: any) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    platform: { value: e.value, label: e.label },
                  })
                }}
              />
            </div>
          )}

          {integrationPanelData.storeName !== undefined && (
            <div className='flex flex-col w-full pt-6'>
              <div className='flex'>
                <span className='text-N-700'>Store Name</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>
              <input
                type='text'
                value={integrationPanelData.storeName}
                onChange={(e: any) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    storeName: e.target.value,
                  })
                }}
                className='py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm mt-2'
              />
            </div>
          )}

          <div className='flex flex-col w-full pt-6'>
            <div className='flex'>
              <span className='text-N-700'>Warehouse</span>
              <span className='text-R-500 ml-[2px]'>*</span>
            </div>

            <SimpleSelect
              options={wareHouseList}
              value={integrationPanelData.wareHouse}
              onChange={(e: any) => {
                checkWMSIntegration(e.warehouseIntegration)
                setIntegrationPanelData({
                  ...integrationPanelData,
                  wareHouse: { value: e.value, label: e.label },
                  isStockValidating:
                    e?.warehouseIntegration.name != 'IcsWMS'
                      ? false
                      : integrationPanelData.isStockValidating,
                })
              }}
            />
          </div>
          {integrationPanelData.apiBaseUrl !== undefined && (
            <div className='flex flex-col w-full pt-6'>
              <div className='flex'>
                <span className='text-N-700'>API Base URL</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>
              <input
                type='text'
                value={integrationPanelData.apiBaseUrl}
                onChange={(e: any) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    apiBaseUrl: e.target.value,
                  })
                }}
                className='py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm mt-2'
              />
            </div>
          )}
          {integrationPanelData.consumerKey !== undefined && (
            <div className='flex flex-col w-full pt-6'>
              <div className='flex'>
                <span className='text-N-700'>Consumer Key</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>
              <input
                type='text'
                value={integrationPanelData.consumerKey}
                onChange={(e: any) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    consumerKey: e.target.value,
                  })
                }}
                className='py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm mt-2'
              />
            </div>
          )}
          {integrationPanelData.consumerSecret !== undefined && (
            <div className='flex flex-col w-full pt-6 relative'>
              <div className='flex'>
                <span className='text-N-700'>Consumer Secret</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>
              <input
                type={showConsumerSecret ? 'text' : 'password'}
                value={integrationPanelData.consumerSecret}
                onChange={(e) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    consumerSecret: e.target.value,
                  })
                }}
                className='py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm mt-2'
              />
              <i
                className={
                  showConsumerSecret
                    ? 'ri-eye-line hover:cursor-pointer absolute right-5 top-[65%] text-N-800 text-lg'
                    : 'ri-eye-off-line hover:cursor-pointer absolute right-5 top-[65%] text-N-800 text-lg'
                }
                onClick={() => {
                  setShowConsumerSecret(!showConsumerSecret)
                }}></i>
            </div>
          )}
          {integrationPanelData.apiPassword !== undefined && (
            <div className='flex flex-col w-full pt-6 relative'>
              <div className='flex'>
                <span className='text-N-700'>API Password</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>
              <input
                type={showApiPassword ? 'text' : 'password'}
                value={integrationPanelData.apiPassword}
                onChange={(e) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    apiPassword: e.target.value,
                  })
                }}
                className='py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm mt-2'
              />
              <i
                className={
                  showApiPassword
                    ? 'ri-eye-line hover:cursor-pointer absolute right-5 top-[65%] text-N-800 text-lg'
                    : 'ri-eye-off-line hover:cursor-pointer absolute right-5 top-[65%] text-N-800 text-lg'
                }
                onClick={() => {
                  setShowApiPassword(!showApiPassword)
                }}></i>
            </div>
          )}
          {integrationPanelData.locationId !== undefined && (
            <div className='flex w-full pt-6'>
              <div className='flex flex-col w-[275px]'>
                <span className='text-N-700 flex'>Location ID</span>
                <SimpleSelect
                  value={integrationPanelData.locationId || ''}
                  onChange={(e: any) => {
                    setIntegrationPanelData({
                      ...integrationPanelData,
                      locationId: e,
                    })
                  }}
                  options={extractLocationData?.shopifyLocationList || {}}
                  disabled={
                    extractLocationData?.shopifyLocationList === undefined ||
                    extractLocationData?.shopifyLocationList.length === 0
                  }
                />
                <span className='text-N-500 text-sm'>
                  This will be enabled once the above two fields are set.
                </span>
              </div>
              <div className='flex items-center justify-center pb-2 pl-6'>
                <Button
                  onClick={() => {
                    callExtractLocation()
                  }}
                  appearance='outline'
                  isLoading={extractLocationIsLoading}
                  disabled={isDisableExtractLocation}>
                  Extract Location ID
                </Button>
              </div>
            </div>
          )}
          {integrationPanelData.apiKey !== undefined && (
            <div className='flex flex-col w-full pt-6'>
              <div className='flex'>
                <span className='text-N-700'>API Key</span>
                <span className='text-R-500 ml-[2px]'>*</span>
              </div>
              <div className='flex w-full'>
                <div className='flex flex-col w-[497px]'>
                  <input
                    disabled
                    value={pushApiKey}
                    className='py-2 px-4 w-full outline-none border border-N-200 rounded-md text-base'
                  />
                </div>
                <Button
                  disabled={!pushApiKey}
                  onClick={() => {
                    setRegenerateKeyConfirmationModalOpen(true)
                  }}
                  className='flex w-52 ml-[10px]'
                  appearance='outline'>
                  Regenerate Key
                </Button>
              </div>
            </div>
          )}

          {regenerateKeyConfirmationModalOpen && (
            <PopUpAlert
              isActive={regenerateKeyConfirmationModalOpen}
              customIcon='warning'
              title='Regenerate PushAPI Key?'
              message='Are you sure you want to proceed? This is not reversible.'
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => setRegenerateKeyConfirmationModalOpen(false)}
              onOverlayClick={() =>
                setRegenerateKeyConfirmationModalOpen(false)
              }
              onConfirmClick={() => {
                regenerateKey()
              }}
            />
          )}
          <div className='flex w-full items-center pt-6'>
            <ToggleSwitch
              text='Sub Courier Account'
              setValue={integrationPanelData.enabledSubCarrierAccount}
              appearance='mini'
              onToggleClick={(e: any) => {
                setIntegrationPanelData({
                  ...integrationPanelData,
                  enabledSubCarrierAccount: e.target.checked,
                })
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

IntegrationPanel.defaultProps = {
  IntegrationData: undefined,
}

export default IntegrationPanel
