/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import CN from 'classnames'
import {
  useCreateShipmentConsignment,
  useCreateShipmentQuotes,
  useGetCarrierProducts,
} from 'framework/api/methods'
import useGetCarrierProductList from 'framework/api/methods/carrier/useGetCarrierProductList'

import { Accordion, Button, PopOut } from 'components/atoms'
import { ConsignedWithoutQuoteModal } from 'components/pages/OrderConsignment/Modals/ConsignedWithoutQuoteModal'
import { SelectCourierServiceModal } from 'components/pages/OrderConsignment/Modals/SelectCourierServiceModal'
import { DataTable as ConsignedPackageTable } from 'components/pages/OrderConsignment/Tables/ConsignedPackageTable'
import { DataTable as ShipmentProductLineTable } from 'components/pages/OrderConsignment/Tables/ShipmentProductLineTable'

import TabMachShip from './TabListContainers/TabMachShip'
import { ConsignedShippingLine } from './ConsignedShippingLine'
import {
  TabAll,
  TabAlliedExpress,
  TabAusPost,
  TabAusPostInternational,
  TabDirect,
  TabSmartSend,
  TabStarShipIt,
} from './TabListContainers'

import 'react-tabs/style/react-tabs.css'
import './ShipmentTabs.scss'

export interface TabComponentProps {
  SelectedShipmentId?: any
  availableProductDetailTableValues?: any
  callOrderShipmentsById?: any
  deleteObjectDetails?: any
  isSelectCourierServiceModalOpen?: boolean
  isConsignWithoutQuoteModalOpen?: boolean
  notify?: any
  selectedOrderDetails?: any
  selectedTabIndex?: number
  setDeleteObjectDetails?: any
  setIsAddNewPackageModalOpen?: any
  setIsAddNewShipmentLineModalOpen?: any
  setIsAddNewShipmentModalOpen?: any
  setIsSelectCourierServiceModalOpen?: any
  setIsConsignWithoutQuoteModalOpen?: any
  setMainLoadingState?: any
  setSelectedShipmentId?: any
  setSelectedTabIndex?: any
  shipmentSectionKey?: any
  onClickBackButton?: any | undefined
  countryCode?: any
}

export const ShipmentTabs: FC<TabComponentProps> = ({
  SelectedShipmentId,
  availableProductDetailTableValues,
  callOrderShipmentsById,
  deleteObjectDetails,
  isConsignWithoutQuoteModalOpen,
  isSelectCourierServiceModalOpen,
  notify,
  selectedOrderDetails,
  selectedTabIndex,
  setDeleteObjectDetails,
  setIsAddNewPackageModalOpen,
  setIsAddNewShipmentLineModalOpen,
  setIsAddNewShipmentModalOpen,
  setIsSelectCourierServiceModalOpen,
  setIsConsignWithoutQuoteModalOpen,
  setMainLoadingState,
  setSelectedShipmentId,
  setSelectedTabIndex,
  shipmentSectionKey,
  onClickBackButton,
  countryCode,
}: TabComponentProps) => {
  const [shipments, setShipments] = useState<any>([])
  const [currentShipmentTabId, setCurrentShipmentTabId] = useState<any>(
    selectedOrderDetails?.shipments[0]?.id,
  )
  const { shippingSummeryWarehouseCode }: any = useSelector<any>(
    (state) => state.order,
  )
  const [isAPXDisable, setIsAPXDisable] = useState(false)

  /**carrier list */
  const getCarrierProductList = useGetCarrierProductList()
  const [carrierList, setCarrierList] = useState<any>([])
  /** Carrier Service types */
  /** Service Type Arrays */
  const [serviceTypes, setServiceTypes] = useState([])
  const [warehouseTimeZoneOffset, setWarehouseTimeZoneOffset] = useState('')

  /** Quote Types are Hard-coded */
  useEffect(() => {
    const warehouseTimeZone = selectedOrderDetails?.warehouse?.timeZone
    const regex = /UTC([+-]\d{2}:\d{2})/
    const match = warehouseTimeZone?.match(regex)
    if (match) {
      const utcOffset = match[0]
      setWarehouseTimeZoneOffset(utcOffset)
    }

    if (selectedOrderDetails) {
      const toBeUpdated = selectedOrderDetails?.shipments?.map(
        (shipment: any) => {
          let isOpen = false
          const savedquoteTypes: any = []
          savedquoteTypes.push({ quoteType: 'ALL', type: 'ALL' })
          if (shipment?.shipmentConsignmentQuotes.length > 0) {
            isOpen = true
            shipment?.shipmentConsignmentQuotes?.map((item: any) => {
              let quoteType = ''
              switch (item.type) {
                case 'ALLIED':
                  quoteType = 'Allied Express'
                  break
                case 'AUSPOST':
                  quoteType = 'Australian Post'
                  break
                case 'AUSPOST_INTERNATIONAL':
                  quoteType = 'Australian Post International'
                  break
                case 'DIRECT':
                  quoteType = 'Direct Express'
                  break
                case 'STARSHIPIT':
                  quoteType = 'Starshipit'
                  break
                case 'MACHSHIP':
                  quoteType = 'MachShip'
                  break
                case 'SMART/SEND':
                  quoteType = 'Smart Send'
                  break
                default:
                  break
              }
              //savedquoteTypes.push({ quoteType: quoteType, type: item.type })
              const existingIndex = savedquoteTypes.findIndex(
                (savedQuote: any) =>
                  savedQuote.quoteType === quoteType &&
                  savedQuote.type === item.type,
              )

              if (existingIndex === -1) {
                savedquoteTypes.push({ quoteType, type: item.type })
              }
            })
          }
          savedquoteTypes.sort((a: any, b: any) => {
            if (a.quoteType < b.quoteType) {
              return -1
            }
            if (a.quoteType > b.quoteType) {
              return 1
            }
            return 0
          })
          return {
            ...shipment,
            isOpenedSavedQuotes: isOpen,
            savedQuoteTypes: savedquoteTypes,
            selectedQuoteType: 'ALL',
          }
        },
      )
      setShipments(toBeUpdated)
    }
  }, [selectedOrderDetails, selectedTabIndex])

  /** Changing the Each tab Saved Quotes accordion open & close */
  const setSavedQuotesOpen = (shipmentId: any) => {
    const toBeUpdated = shipments?.map((shipment: any) => {
      if (shipment.id === shipmentId) {
        return {
          ...shipment,
          isOpenedSavedQuotes: !shipment.isOpenedSavedQuotes,
        }
      }
      return {
        ...shipment,
        isOpenedSavedQuotes: false,
      }
    })
    setShipments(toBeUpdated)
  }

  const searchForSavedQuotes = (quotes: any, type: any) => {
    const isQuoteThere = quotes.find((quote: any) => {
      if (type === 'ALL') {
        return quotes
      } else {
        return quote.type === type
      }
    })

    if (isQuoteThere === undefined) {
      return (
        <div className='flex items-center justify-center w-full h-[260px]'>
          <div className='flex flex-col items-center'>
            <p className='text-lg font-semibold text-N-700'>
              No Saved Quotes Found
            </p>
            <p className='text-sm font-medium text-N-500 pt-2'>
              Please try Get Quote
            </p>
          </div>
        </div>
      )
    }
  }

  /** API Call for Get Quote for Shipment */
  const {
    mutate: createShipmentQuotesMutate,
    isLoading: createShipmentQuotesIsLoading,
  } = useCreateShipmentQuotes()

  /** Process the Get Quote for Shipment */
  async function createShipmentQuotes(
    wereHouseOrderId: string,
    shipmentId: string,
    quoteType: string,
  ) {
    createShipmentQuotesMutate(
      { wereHouseOrderId, shipmentId, quoteType },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          callOrderShipmentsById()
          setIsSelectCourierServiceModalOpen(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Consign Shipment */
  const {
    mutate: createShipmentConsignmentMutate,
    isLoading: createShipmentConsignmentIsLoading,
  } = useCreateShipmentConsignment()

  /** Process the Consign Shipment */
  async function createShipmentConsignment(
    wereHouseOrderId: string,
    shipmentId: string,
    quoteType: string,
    productCode: string,
    ATLValue: boolean,
    personToLift: number,
    pickUpDate: string,
    starshipITCourier: any,
  ): Promise<void> {
    createShipmentConsignmentMutate(
      {
        wereHouseOrderId,
        shipmentId,
        quoteType,
        productCode,
        ATLValue,
        personToLift,
        pickUpDate,
        starshipITCourier,
        warehouseCode: shippingSummeryWarehouseCode || null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          callOrderShipmentsById()
          setIsConsignWithoutQuoteModalOpen(false)

          successData.consignment.type !== 'DSE' &&
            window.open(successData.consignment.labelUrl, '_blank')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** Handling Creating Shipment Click */
  const handleSelectCourierServiceConfirmClick = (quoteType: any) => {
    createShipmentQuotes(selectedOrderDetails.id, SelectedShipmentId, quoteType)
  }

  /** Handling Consign Shipment Click */
  const handleConsignClick = (
    shipmentId: any,
    quoteType: any,
    productCode: any,
    ATLValue: any,
    personToLift: any,
    pickUpDate: any,
    starshipITCourier: any,
  ) => {
    createShipmentConsignment(
      selectedOrderDetails.id,
      shipmentId,
      quoteType,
      productCode,
      ATLValue,
      personToLift,
      pickUpDate,
      starshipITCourier,
    )
  }

  /** Main Loading Status handling with every single API Loading Status's */
  useEffect(() => {
    if (createShipmentQuotesIsLoading || createShipmentConsignmentIsLoading) {
      setMainLoadingState(true)
    } else {
      setMainLoadingState(false)
    }
  }, [createShipmentQuotesIsLoading, createShipmentConsignmentIsLoading])

  /** check if one of the package weight is more than 13KG for each tabs */
  useEffect(() => {
    const selectedShipmentIdObject = selectedOrderDetails?.shipments?.find(
      (item: any) => item?.id === currentShipmentTabId,
    )

    const isOverWeight = selectedShipmentIdObject?.packages?.some(
      (item: any) => item?.weight > 13,
    )

    setIsAPXDisable(isOverWeight)
  }, [currentShipmentTabId, selectedOrderDetails, selectedTabIndex])

  /**fetch courier products */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getCarrierProductList.mutateAsync({
          countryCode: countryCode,
        })
        const carrierProducts = response.data.carrierProducts
        const uniqueCarriersMap = new Map()

        const transformedData = carrierProducts.reduce(
          (result: any[], product: { carrier: any }) => {
            if (!uniqueCarriersMap.has(product.carrier)) {
              uniqueCarriersMap.set(product.carrier, product)
              result.push({
                value: product.carrier,
                label: product.carrier,
                ...product, // Include all properties from the original product
              })
            }
            return result
          },
          [],
        )
        setCarrierList(transformedData)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchProducts()
  }, [serviceTypes])

  return (
    <div key={shipmentSectionKey} className='flex w-full relative mt-7'>
      <Tabs defaultIndex={selectedTabIndex}>
        <TabList>
          {shipments?.map((shipment: any, index: any) => (
            <Tab
              onClick={() => {
                setSelectedTabIndex(index)
                setCurrentShipmentTabId(shipment.id)
              }}
              key={shipment.id}
              className={CN('react-tabs__tab text-sm font-bold py-[9px]', {
                'text-G-500 border-x-0': shipment?.status === 'CLOSED',
                'text-N-800 border-0': shipment?.status !== 'CLOSED',
              })}>
              {shipment?.name}
              <i
                className={CN(
                  'ri-close-fill text-N-300 hover:text-N-500 active:text-N-600 pl-2',
                  {
                    'cursor-not-allowed':
                      shipment?.status === 'CLOSED' ||
                      shipment?.status === 'CANCELLED',
                  },
                )}
                onClick={() => {
                  if (
                    shipment?.status !== 'CLOSED' &&
                    shipment?.status !== 'CANCELLED'
                  ) {
                    setDeleteObjectDetails({
                      ...deleteObjectDetails,
                      trigger: true,
                      deleteData: shipment,
                      method: 'deleteShipment',
                      deleteHeading: 'Delete Shipment?',
                      deleteDescription:
                        'Are you sure you want to proceed? This is not reversible.',
                    })
                  }
                }}></i>
            </Tab>
          ))}
        </TabList>

        {shipments?.map((shipment: any, index: any) => (
          <TabPanel key={shipment.id}>
            <div className='flex flex-col w-full bg-white pt-8 pb-4 px-4'>
              <div className='flex flex-col'>
                {/* If the Shipment is Consigned following msg will shown */}
                {shipment?.status === 'CLOSED' && (
                  <PopOut appearance='success' size='small' className='mb-3'>
                    Consigned
                  </PopOut>
                )}

                <div className='flex w-full'>
                  <div className='flex flex-col w-7/12'>
                    <div className='flex items-center justify-between pb-5'>
                      <h2 className='text-sm font-medium text-N-700'>
                        Shipment Products
                      </h2>
                      {shipment?.status !== 'CLOSED' && (
                        <Button
                          onClick={() => {
                            setSelectedShipmentId(shipment.id)
                            setIsAddNewShipmentLineModalOpen(true)
                          }}
                          disabled={
                            availableProductDetailTableValues.length === 0 ||
                            shipment?.status === 'CANCELLED'
                          }
                          className='w-28'
                          size='xs'
                          appearance='outline'>
                          <i className='ri-add-line pr-3'></i>New Line
                        </Button>
                      )}
                    </div>
                    <ShipmentProductLineTable
                      itemData={shipment.shipmentLine}
                      className={undefined}
                      setDeleteObjectDetails={setDeleteObjectDetails}
                      deleteObjectDetails={deleteObjectDetails}
                      shipmentId={shipment.id}
                      shipmentStatus={shipment.status}
                    />
                  </div>

                  <div className='flex flex-col w-5/12 ml-10'>
                    <div className='flex items-center justify-between pb-5'>
                      <h2 className='text-sm font-medium text-N-700'>
                        Shipment Packages
                      </h2>
                      {shipment?.status !== 'CLOSED' && (
                        <Button
                          disabled={
                            shipment?.shipmentLine?.length === 0 ||
                            shipment?.status === 'CANCELLED'
                          }
                          onClick={() => {
                            setSelectedShipmentId(shipment.id)
                            setIsAddNewPackageModalOpen(true)
                          }}
                          size='xs'
                          appearance='outline'>
                          <i className='ri-add-line pr-3'></i>New Package
                        </Button>
                      )}
                    </div>
                    <div
                      className={CN('flex flex-col w-full h-full', {
                        'justify-center items-center':
                          shipment?.packages?.length === 0,
                      })}>
                      {shipment?.packages &&
                        shipment?.packages?.map((item: any, index: any) => (
                          <div
                            key={index}
                            className='flex items-center justify-between w-full rounded-lg border border-N-200 px-4 py-3 mb-4 cursor-pointer'>
                            <p className='text-sm text-N-700 font-semibold'>
                              {`Package ${index + 1}`}
                            </p>
                            <p className='text-sm font-normal text-N-500'>
                              {item.type}
                            </p>
                            <p className='text-sm font-normal text-N-500'>
                              {`${item.length} x ${item.width} x ${item.height} CM | ${item.weight} KG`}
                            </p>
                            {shipment?.status !== 'CLOSED' && (
                              <Button
                                disabled={shipment?.status === 'CANCELLED'}
                                onClick={() => {
                                  setDeleteObjectDetails({
                                    ...deleteObjectDetails,
                                    trigger: true,
                                    deleteData: {
                                      ...item,
                                      shipmentId: shipment.id,
                                    },
                                    method: 'deleteShipmentPackage',
                                    deleteHeading: 'Delete Shipment Package?',
                                    deleteDescription:
                                      'Are you sure you want to proceed? This is not reversible.',
                                  })
                                }}
                                appearance='danger'
                                size='xs'>
                                <i className='ri-delete-bin-6-line text-R-500'></i>
                              </Button>
                            )}
                          </div>
                        ))}
                      {shipment?.packages?.length === 0 && (
                        <div className='flex flex-col items-center'>
                          <p className='text-lg font-semibold text-N-700'>
                            No Packages Found
                          </p>
                          <p className='text-sm font-medium text-N-500 pt-2'>
                            You need atleast one package to get a quote
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Get Quote Buttons will show only for not consigned shipments */}
                {shipment?.status !== 'CLOSED' && (
                  <div className='w-full border-t border-t-N-200 mt-10 px-5 py-4'>
                    <Button
                      onClick={() => {
                        setSelectedShipmentId(shipment.id)
                        setIsSelectCourierServiceModalOpen(true)
                      }}
                      disabled={
                        shipment?.packages?.length === 0 ||
                        shipment?.status === 'CANCELLED'
                      }
                      isLoading={false}>
                      Get Quote
                    </Button>
                    <Button
                      className='ml-5'
                      appearance='outline'
                      disabled={
                        shipment?.packages?.length === 0 ||
                        shipment?.status === 'CANCELLED'
                      }
                      onClick={() => {
                        setIsConsignWithoutQuoteModalOpen(true)
                        setSelectedShipmentId(shipment.id)
                      }}>
                      Consign without Quote
                    </Button>
                  </div>
                )}

                {/* The Consigned Summery will only be shown to the consigned Shipments only */}
                {shipment?.status === 'CLOSED' && (
                  <div className='flex flex-col w-full pt-12 '>
                    <div className='flex w-full items-center justify-between'>
                      <div className='left-con flex items-center'>
                        <i className='ri-checkbox-circle-line text-[40px] text-G-500'></i>
                        <div className='flex flex-col items-start pl-3'>
                          <p className='font-semibold text-black'>
                            Consigned with
                            {shipment?.consignment?.type === 'ALLIED' &&
                              ' Allied Express'}
                            {shipment?.consignment?.type === 'AUSPOST' &&
                              ' Australia Post'}
                            {shipment?.consignment?.type ===
                              'AUSPOST_INTERNATIONAL' &&
                              ' Australia Post International'}
                            {shipment?.consignment?.type === 'DIRECT' &&
                              ' Direct Express'}
                            {shipment?.consignment?.type === 'DSE' && ' DSE'}
                            {shipment?.consignment?.type === 'SMART/SEND' &&
                              ' Smart Send'}
                            {shipment?.consignment?.type === 'STARSHIPIT' &&
                              ' Starshipit'}
                            {shipment?.consignment?.type === 'MACHSHIP' &&
                              ' MachShip'}
                          </p>
                          <p className='text-sm font-medium text-N-500 pt-1'>
                            {shipment?.consignment?.type === 'ALLIED' &&
                              'Allied Express - '}
                            {shipment?.consignment?.type === 'AUSPOST' &&
                              'Australia Post - '}
                            {shipment?.consignment?.type ===
                              'AUSPOST_INTERNATIONAL' &&
                              'Australia Post International - '}
                            {shipment?.consignment?.type === 'DIRECT' &&
                              'Direct Express - '}
                            {shipment?.consignment?.type === 'DSE' && 'DSE - '}
                            {shipment?.consignment?.type === 'SMART/SEND' &&
                              'Smart Send - '}

                            {shipment?.consignment?.productName}

                            {shipment?.consignment?.type === 'STARSHIPIT' &&
                              ' - ' + shipment?.consignment?.productCode}

                            {shipment?.consignment?.type === 'MACHSHIP' &&
                              ' - ' + shipment?.consignment?.productCode}
                          </p>
                        </div>
                      </div>

                      <Button
                        appearance='dull'
                        disabled={shipment?.consignment?.type === 'DSE'}
                        size='sm'
                        className='text-N-700'
                        onClick={() => {
                          window.open(shipment?.consignment?.labelUrl, '_blank')
                        }}>
                        Print All Labels
                      </Button>
                    </div>
                    <ConsignedPackageTable
                      itemData={shipment?.consignment?.shippmentConsignmentItems.map(
                        (item: any) => ({
                          ...item,
                          consignedType: shipment?.consignment?.type,
                          pickupDate: shipment?.dateWanted,
                        }),
                      )}
                      className='mt-7 mb-6'
                    />
                    <ConsignedShippingLine
                      key={shipment.id}
                      shipment={shipment}
                    />
                  </div>
                )}
              </div>
            </div>

            <Button
              onClick={onClickBackButton}
              className='mt-3'
              size='xs'
              appearance='outline'>
              <i className='ri-arrow-left-line pr-3'></i> Back to single order
              consignment
            </Button>

            <div className='flex flex-col w-full bg-white mt-8 mb-8 py-4  px-4 rounded-lg'>
              <Accordion
                key='Saved Quotes'
                isOpen={shipment.isOpenedSavedQuotes}
                title='Saved Quotes'
                OnClick={() => {
                  setSavedQuotesOpen(shipment.id)
                }}>
                <div className='flex w-full quote-section pt-4'>
                  <Tabs>
                    <TabList>
                      {shipment?.savedQuoteTypes?.map(
                        (savedQuoteType: any, index: any) => (
                          <Tab
                            key={index + 1}
                            className='react-tabs__tab text-sm font-bold text-N-800 py-[9px] cursor-pointer border-x-0'>
                            {savedQuoteType.quoteType}
                          </Tab>
                        ),
                      )}
                    </TabList>

                    <TabPanel>
                      <TabAll
                        shipment={shipment}
                        selectedOrderId={selectedOrderDetails.id}
                        searchForSavedQuotes={searchForSavedQuotes}
                        notify={notify}
                        callOrderShipmentsById={callOrderShipmentsById}
                        setMainLoadingState={setMainLoadingState}
                        isAPXDisable={isAPXDisable}
                      />
                    </TabPanel>

                    {shipment?.savedQuoteTypes?.map((item: any, index: any) => {
                      if (item.type === 'AUSPOST') {
                        return (
                          <TabPanel key={index}>
                            <TabAusPost
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                            />
                          </TabPanel>
                        )
                      }

                      if (item.type === 'AUSPOST_INTERNATIONAL') {
                        return (
                          <TabPanel key={index}>
                            <TabAusPostInternational
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                              isAPXDisable={isAPXDisable}
                            />
                          </TabPanel>
                        )
                      }

                      if (item.type === 'DIRECT') {
                        return (
                          <TabPanel key={index}>
                            <TabDirect
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                            />
                          </TabPanel>
                        )
                      }

                      if (item.type === 'STARSHIPIT') {
                        return (
                          <TabPanel key={index}>
                            <TabStarShipIt
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                            />
                          </TabPanel>
                        )
                      }

                      if (item.type === 'MACHSHIP') {
                        return (
                          <TabPanel key={index}>
                            <TabMachShip
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                            />
                          </TabPanel>
                        )
                      }

                      if (item.type === 'SMART/SEND') {
                        return (
                          <TabPanel key={index}>
                            <TabSmartSend
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                            />
                          </TabPanel>
                        )
                      }

                      if (item.type === 'ALLIED') {
                        return (
                          <TabPanel>
                            <TabAlliedExpress
                              shipment={shipment}
                              selectedOrderId={selectedOrderDetails.id}
                              searchForSavedQuotes={searchForSavedQuotes}
                              notify={notify}
                              callOrderShipmentsById={callOrderShipmentsById}
                              setMainLoadingState={setMainLoadingState}
                            />
                          </TabPanel>
                        )
                      }
                    })}
                  </Tabs>
                </div>
              </Accordion>
            </div>
          </TabPanel>
        ))}
      </Tabs>
      <div className='absolute top-0 right-0'>
        <Button
          onClick={() => {
            setIsAddNewShipmentModalOpen(true)
          }}
          disabled={availableProductDetailTableValues.length === 0}
          size='sm'
          appearance='outline'>
          <i className='ri-add-line pr-3'></i>New Shipment
        </Button>
      </div>
      {isSelectCourierServiceModalOpen && (
        <SelectCourierServiceModal
          isActive={isSelectCourierServiceModalOpen}
          onCloseClick={() => {
            setIsSelectCourierServiceModalOpen(false)
          }}
          onOverlayClick={() => {
            setIsSelectCourierServiceModalOpen(false)
          }}
          handleSelectCourierServiceConfirmClick={
            handleSelectCourierServiceConfirmClick
          }
          selectedOrderDetails={selectedOrderDetails}
          selectedShipmentId={SelectedShipmentId}
          carrierList={carrierList}
        />
      )}
      {isConsignWithoutQuoteModalOpen && (
        <ConsignedWithoutQuoteModal
          isActive={isConsignWithoutQuoteModalOpen}
          onCloseClick={() => {
            setIsConsignWithoutQuoteModalOpen(false)
          }}
          onOverlayClick={() => {
            setIsConsignWithoutQuoteModalOpen(false)
          }}
          handleConsignWithoutQuoteConfirmClick={handleConsignClick}
          selectedShipmentId={SelectedShipmentId}
          useGetCarrierProducts={useGetCarrierProducts}
          notify={notify}
          shipments={shipments}
          isBulkConsignment={false}
          selectedOrderDetails={selectedOrderDetails}
          isAPXDisable={isAPXDisable}
          carrierList={carrierList}
          warehouseTimezone={warehouseTimeZoneOffset || ''}
        />
      )}
    </div>
  )
}

ShipmentTabs.defaultProps = {
  onClickBackButton: undefined,
}

export default ShipmentTabs
