/* eslint-disable prettier/prettier */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetUserPriviledgesByCategoriesInterface {
  id?: any
}

export const useGetUserPriviledgesByCategories = () => {
  const mutate = useMutation(({ id }: GetUserPriviledgesByCategoriesInterface) => {
    return get(`/identity/CategorizeduserPriviledgeList`)
  })

  return mutate
}

export default useGetUserPriviledgesByCategories