/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useGetCutoffTime,
  useGetManifestListByCourierAndWarehouse,
  useGetStarshipITCarriers,
  useGetWarehouseTable,
  useManifestByTrackingNumbers,
} from 'framework/api/methods'

import { SimpleSelect } from 'components/atoms'
import { Button } from 'components/atoms/Button'

import { DataTable } from './StarshipItManifestDataTable'

export interface StarshipItManifestingProps {
  [x: string]: any
}

export const StarshipItManifesting: FC<StarshipItManifestingProps> = ({
  className,
  ...restProps
}: StarshipItManifestingProps) => {
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [wareHouseList, setWareHouseList] = useState([] as any)
  const [starshipitCourierList, setStarshipitCourierList] = useState<any>([])
  const [wareHouse, setWarehouse] = useState<any>({
    label: 'All',
    value: 'All',
  })
  const [carrier, setCarrier] = useState<any>({ label: 'All', value: 'All' })
  const [cutoffTime, setCutoffTime] = useState<any>(null)
  const [isManifestOrderEnable, setIsManifestOrderEnable] = useState(false)
  const [tableVariables, setTableVariables] = useState<any>({
    take: 10,
    skip: 0,
    Search: '',
  })
  const [cutoffTimeDetails, setCutoffTimeDetails] = useState<any>({
    originalTime: '',
    newTime: '',
  })

  /** Function On-Error Get Currier Products*/
  const getManifestListByCourierAndWarehouseOnError = ({
    response: { data: errData },
  }: any) => {
    errData.message && notify(errData.message, 'error')
  }

  /** API Call for get manifest list function using react-query */
  const { isLoading, isFetching, data, refetch } =
    useGetManifestListByCourierAndWarehouse(
      {
        ...tableVariables,
        courierCode: `STARSHIPIT`,
        subCourier: carrier?.label || '',
        warehouseCode: wareHouse?.value || '',
      },
      getManifestListByCourierAndWarehouseOnError,
    )

  /** API Call for get cutoff time using react-query */
  const { mutate: getCutOffTimeMutate } = useGetCutoffTime()

  /** Process the get cutoff time */
  async function getCutoffTime() {
    getCutOffTimeMutate(
      {
        warehouseCode: wareHouse?.value,
        courierCode: `STARSHIPIT`,
        subCourierCode: carrier?.value,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData?.cutoffTime?.status &&
            setCutoffTime(successData?.cutoffTime?.cutofffTime)
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }

  const { mutate: getWarehouseListMutate } = useGetWarehouseTable()

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { take: 100, skip: 0, name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.warehouses?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: `${item.code} - ${item.name}`,
              id: item.id,
            }
          })
          setWareHouseList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  const getStarshipITCarriersOnSuccess = (data: any) => {
    setStarshipitCourierList(data?.starshipitCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getStarshipITCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of starshipit */
  const { refetch: getStarshipITCarriers } = useGetStarshipITCarriers(
    {},
    getStarshipITCarriersOnSuccess,
    getStarshipITCarriersOnError,
  )

  useEffect(() => {
    getWarehouseList()
    getStarshipITCarriers()
  }, [])

  useEffect(() => {
    wareHouse?.value !== 'All' && carrier?.value !== 'All' && getCutoffTime()
  }, [wareHouse?.value, carrier?.value])

  /** check now time is between cutoff time and duration range*/
  const isNowBetweenTimes = (timeString: any) => {
    const [hours, minutes, period] = timeString.split(/:| /)
    let hoursNum = parseInt(hours)
    const minutesNum: any = parseInt(minutes)

    if (period === 'PM' && hoursNum !== 12) {
      hoursNum += 12
    } else if (period === 'AM' && hoursNum === 12) {
      hoursNum = 0
    }

    const originalTime = new Date()
    originalTime.setHours(hoursNum)
    originalTime.setMinutes(minutesNum)

    // NOTE : as per the client requirement Default Time duration is 30 minutes
    const newTime = new Date(originalTime)
    newTime.setMinutes(originalTime.getMinutes() + 30)

    setCutoffTimeDetails({
      originalTime: originalTime.toString(),
      newTime: newTime.toString(),
    })

    const now = new Date()
    const isBetweenTimes = now >= originalTime && now <= newTime

    return isBetweenTimes
  }

  const dateAndTimeIntoReadableFormat = (date: any) => {
    const dateObject = new Date(date)

    const hours = dateObject.getHours()
    const meridiem = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12 // Convert to 12-hour format

    const formattedDate =
      ('0' + dateObject.getDate()).slice(-2) +
      '/' +
      ('0' + (dateObject.getMonth() + 1)).slice(-2) +
      '/' +
      dateObject.getFullYear() +
      ' ' +
      ('0' + formattedHours).slice(-2) +
      ':' +
      ('0' + dateObject.getMinutes()).slice(-2) +
      ' ' +
      meridiem

    return formattedDate
  }

  useEffect(() => {
    cutoffTime && setIsManifestOrderEnable(isNowBetweenTimes(cutoffTime))
  }, [cutoffTime])

  /** API Call for manifest by tracking numbers using react-query */
  const {
    mutate: manifestByTrackingNumbersMutate,
    isLoading: manifestByTrackingNumbersIsLoading,
  } = useManifestByTrackingNumbers()

  /** Process the  manifest by tracking numbers */
  async function manifestByTrackingNumbers() {
    manifestByTrackingNumbersMutate(
      {
        trackingNumbers: data?.trackingNumber,
        courierCode: `STARSHIPIT`,
        warehouseCode: wareHouse?.value,
        subCourier: carrier?.label,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          refetch()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  const { userDetails }: any = useSelector<any>((state) => state.user)

  // ---- Get the current date Format the date ---- //
  const today = new Date()

  const day = today.getDate()
  const month = today.toLocaleString('default', { month: 'long' })

  const getDayWithSuffix = (day: any) => {
    if (day >= 11 && day <= 13) {
      return day + 'th'
    }
    switch (day % 10) {
      case 1:
        return day + 'st'
      case 2:
        return day + 'nd'
      case 3:
        return day + 'rd'
      default:
        return day + 'th'
    }
  }

  const formattedDate = getDayWithSuffix(day) + ' ' + month

  const permissionForManifestOrder = userDetails?.userPermissions?.includes(
    'StarshipitManifestAccess',
  )
  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}
      {...restProps}>
      <div className='flex flex-col w-full h-full px-5 bg-white rounded-lg shadow-md'>
        <div className='py-[28px]'>
          <h2 className='text-lg font-semibold'>
            Manifest Order from Starshipit for {formattedDate}
          </h2>
        </div>
        <div className='flex justify-between items-end'>
          <div className='flex gap-x-4 w-1/2'>
            <div className='flex flex-col w-1/2 z-[2]'>
              <span className='pb-1 text-N-700'>Starshipit Courier</span>
              <SimpleSelect
                value={carrier || null}
                onChange={(e: any) => {
                  setCutoffTime(null)
                  setCarrier({
                    value: e.value,
                    label: e.label,
                  })
                }}
                placeholder='StarshipIT Carrier'
                options={[
                  { label: 'All', value: 'All' },
                  ...starshipitCourierList,
                ]}
              />
            </div>

            <div className='flex flex-col w-1/2 z-[2]'>
              <span className='pb-1 text-N-700'>Warehouse Name</span>

              <SimpleSelect
                value={wareHouse || null}
                onChange={(e: any) => {
                  setCutoffTime(null)
                  setWarehouse({
                    value: e.value,
                    label: e.label,
                  })
                }}
                placeholder='Warehouse'
                options={[{ label: 'All', value: 'All' }, ...wareHouseList]}
              />
            </div>
          </div>

          {permissionForManifestOrder && (
            <div className='flex gap-x-5'>
              {data?.manifestFile && (
                <Button
                  appearance='outline'
                  onClick={() => {
                    const pdfUrl = data?.manifestFile

                    const downloadLink = document.createElement('a')
                    downloadLink.href = pdfUrl
                    downloadLink.target = '_blank'
                    downloadLink.download = 'manifestFile.pdf'

                    downloadLink.click()
                  }}>
                  Manifest Report Download
                </Button>
              )}
              <Button
                isLoading={manifestByTrackingNumbersIsLoading}
                disabled={
                  !(
                    isManifestOrderEnable && data?.manifestOrders?.length > 0
                  ) ||
                  data?.manifestOrders?.some((item: any) => item.isManifest) ||
                  carrier?.value === 'All' ||
                  wareHouse?.value === 'All'
                }
                onClick={() => {
                  if (isNowBetweenTimes(cutoffTime)) {
                    !manifestByTrackingNumbersIsLoading &&
                      manifestByTrackingNumbers()
                  } else {
                    notify(
                      'Manifesting is disabled as the cutoff period is exceeded.',
                      'error',
                    )
                  }
                }}>
                Manifest Order
              </Button>
            </div>
          )}
        </div>
        {cutoffTime && (
          <div className='mt-3 pb-2 flex'>
            <span className='font-semibold pr-1'>
              Manifesting Enable Time :
            </span>
            {dateAndTimeIntoReadableFormat(cutoffTimeDetails?.originalTime)} -{' '}
            {dateAndTimeIntoReadableFormat(cutoffTimeDetails?.newTime)}
          </div>
        )}
        <div className='w-full h-[calc(100%-160px)] pt-4'>
          <DataTable
            itemData={data?.manifestOrders || []}
            setTableVariables={setTableVariables}
            tableVariables={tableVariables}
            totalRowCount={data?.totalCount}
            isLoading={isLoading || isFetching}
            isManifesting={manifestByTrackingNumbersIsLoading}
          />
        </div>
      </div>

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

StarshipItManifesting.defaultProps = {}

export default StarshipItManifesting
