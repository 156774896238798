import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

import { Button } from 'components/atoms/Button'

export interface OrderFooterProps {
  [x: string]: any
  onClickCancelConsignmentButton?: any
  isCancelConsignmentOrderBtn?: boolean | undefined
  orderDetails?: any
  onClickHoldOrderButton?: any
  onClickReleaseOrder?: any
  isOnHoldLoading?: boolean | undefined
  isReleaseLoading?: boolean | undefined
  isOnHoldOrderBtn?: boolean | undefined
  isReleaseOrderButton?: boolean | undefined
}

/** This is OrderFooter */

export const OrderFooter: FC<OrderFooterProps> = ({
  onClick,
  isLoading,
  isCancelOrderBtn,
  isRetryOrderBtn,
  isCancelConsignmentOrderBtn,
  onClickCancelButton,
  onClickCancelConsignmentButton,
  orderDetails,
  onClickHoldOrderButton,
  onClickReleaseOrder,
  isOnHoldLoading,
  isReleaseLoading,
  isOnHoldOrderBtn,
  isReleaseOrderButton,
  whTimeZone, //this prop is used to get this warehouse time zone property from the backend in a future development
}: OrderFooterProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const permissionForOrderRetry = userDetails?.userPermissions?.includes(
    'WarehouseOrderRetryAccess',
  )

  const permissionForOrderCancel = userDetails?.userPermissions?.includes(
    'PartnerOrderCancelAccess',
  )

  const permissionForPartnerOrderHoldReleaseAccess =
    userDetails?.userPermissions?.includes('PartnerOrderHoldReleaseAccess')

  const permissionForConsignOrderCancel =
    userDetails?.userPermissions?.includes('OrderConsignmentCancel')

  const IsCancelConsignmentEligible = (orderConsignedDateTime: any) => {
    whTimeZone = 'AEST (UTC+10:00)' //here the hard coded value is used for now, when the prop value is passed here from the backend replace this value.
    const isEligible = compareDateTime(whTimeZone, orderConsignedDateTime)
    return isEligible
  }

  const compareDateTime = (
    whTimeZone: string,
    orderConsignedDateTime: string,
  ) => {
    const regex = /([A-Z]+) \((UTC[+|-]\d+:\d+)\)/
    const matches = whTimeZone.match(regex)

    if (matches && matches.length === 3) {
      const utcOffset = matches[2]
      if (utcOffset) {
        const pattern = utcOffset.match(/UTC([+|-]\d+:\d+)/)
        if (pattern && pattern.length > 1) {
          const offsetParts = pattern[1].split(':')
          const hours = parseInt(offsetParts[0], 10)
          const minutes = parseInt(offsetParts[1], 10)
          const currentUTCDateTime = moment.utc()
          const targetTimeZoneOffset = hours * 60 + minutes
          const convertedWhDate = currentUTCDateTime
            .clone()
            .add(targetTimeZoneOffset, 'minutes')
            .format('YYYY-MM-DD')

          const convertedOrderConsignedDate = moment(orderConsignedDateTime)
            .add(targetTimeZoneOffset, 'minutes')
            .format('YYYY-MM-DD')
          return convertedOrderConsignedDate === convertedWhDate
        }
      }
    }
    return null // Return null for invalid format
  }

  return (
    <div className='footer_container flex w-full pb-3 px-4 pt-4 border-t border-t-N-200 sticky bottom-0 bg-white z-[1]'>
      {isRetryOrderBtn && permissionForOrderRetry && (
        <Button
          className='mr-5'
          onClick={onClick}
          isLoading={isLoading}
          disabled={isLoading}>
          Retry Order
        </Button>
      )}
      {isCancelOrderBtn && permissionForOrderCancel && (
        <Button
          className='text-R-500 border-R-500 hover:bg-N-50 mr-5'
          appearance='outline'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onClickCancelButton}>
          Cancel Order
        </Button>
      )}

      {isOnHoldOrderBtn && permissionForPartnerOrderHoldReleaseAccess && (
        <Button
          className='text-N-800 border-N-800 bg-N-50 hover:bg-N-100 mr-5'
          appearance='outline'
          isLoading={isOnHoldLoading}
          disabled={isOnHoldLoading}
          onClick={onClickHoldOrderButton}>
          Hold Order
        </Button>
      )}

      {isReleaseOrderButton && permissionForPartnerOrderHoldReleaseAccess && (
        <Button
          className='text-N-800 border-N-800 bg-N-50 hover:bg-N-100 mr-5'
          appearance='outline'
          isLoading={isReleaseLoading}
          disabled={isReleaseLoading}
          onClick={onClickReleaseOrder}>
          Release Order
        </Button>
      )}

      {isCancelConsignmentOrderBtn &&
        permissionForConsignOrderCancel &&
        IsCancelConsignmentEligible(
          orderDetails?.partnerOrderConsignment?.createdOn,
        ) &&
        orderDetails?.partnerOrderConsignment?.carrierName !== 'STARSHIPIT' && (
          <Button
            className='text-R-500 border-R-500 hover:bg-N-50'
            appearance='outline'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onClickCancelConsignmentButton}>
            Cancel Consignment Order
          </Button>
        )}
    </div>
  )
}

OrderFooter.defaultProps = {
  isCancelOrderBtn: true,
  isRetryOrderBtn: false,
  isOnHoldOrderBtn: false,
  isReleaseOrderButton: false,
  isCancelConsignmentOrderBtn: false,
  onClickCancelConsignmentButton: undefined,
  onClickHoldOrderButton: undefined,
  onClickReleaseOrder: undefined,
  isOnHoldLoading: false,
  isReleaseLoading: false,
}

export default OrderFooter
