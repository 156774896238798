/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPlatformInterface {
  type: string
}

export const useGetPlatformByType = () => {
  const mutate = useMutation(({ type }: GetPlatformInterface) => {
    return get(`/OrderPlatform?Type=${type}`)
  })

  return mutate
}

export default useGetPlatformByType
