import { FC } from 'react'
import CN from 'classnames'

import { LeftNav } from 'components/atoms/LeftNav'
import { Logo } from 'components/atoms/Logo'

export interface SideBarProps {
  [x: string]: any
  isFullMenu?: boolean
  firstRoute?: any
  secondRoute?: any
  menuItemList?: any
  userDetails?: any
}

export const SideBar: FC<SideBarProps> = ({
  className,
  isFullMenu,
  firstRoute,
  secondRoute,
  menuItemList,
  userDetails,
  ...restProps
}: SideBarProps) => {
  const SideBarClasses = CN(
    'main-layout__sidebar z-10 fixed left-0 bg-white h-full shadow transition-all duration-200',
    {
      'w-[280px]': isFullMenu,
      'w-[60px]': !isFullMenu,
    },
    className,
  )

  return (
    <div className={SideBarClasses} {...restProps}>
      <div className='flex flex-col items-center w-full h-full px-[10px] py-6'>
        {isFullMenu ? <Logo isSidebar={true} /> : <Logo appearance='mini' />}
        <LeftNav
          menuItemList={menuItemList}
          isFullMenu={isFullMenu}
          firstRoute={firstRoute || ''}
          secondRoute={secondRoute}
          userDetails={userDetails}
        />
      </div>
    </div>
  )
}

SideBar.defaultProps = {
  onClick: undefined,
  menuItemList: undefined,
  firstRoute: undefined,
  secondRoute: undefined,
}

export default SideBar
