import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { timeIntoDateTimeFormat } from 'utils'

interface UpdateOrderCutoffTimeInterface {
  id?: string
  partnerCode?: string
  partnerName?: string
  integrationType?: string
  integrationId?: string
  integrationURL?: string
  cutoffTime?: string
  beforeCutoffTime?: number
  afterCutoffTime?: number
  status?: boolean
}

export const useUpdateOrderCutoffTime = () => {
  const mutate = useMutation(
    ({
      id,
      partnerCode,
      partnerName,
      integrationType,
      integrationId,
      cutoffTime,
      beforeCutoffTime,
      afterCutoffTime,
      status,
    }: UpdateOrderCutoffTimeInterface) => {
      return putAPI(`/UpdatePartnerCutoffTime`, {
        id,
        partnerCode,
        partnerName,
        integrationType,
        integrationId,
        cutofffTime: timeIntoDateTimeFormat(cutoffTime),
        beforeCutOffLeadTime: beforeCutoffTime,
        afterCutOffLeadTime: afterCutoffTime,
        status,
      })
    },
  )

  return mutate
}

export default useUpdateOrderCutoffTime
