import { FC } from 'react'
import BdlLogo from 'assets/images/bdl-logo.svg'
import BdlLogoMini from 'assets/images/mini-logo.png'
import CN from 'classnames'

export interface LogoProps {
  className?: string | undefined
  appearance?: 'default' | 'mini' | undefined
  onClick?: (e: any) => void | undefined
  isSidebar?: boolean
  [x: string]: any
}

export const Logo: FC<LogoProps> = ({ appearance, isSidebar }: LogoProps) => {
  return (
    <>
      {appearance === 'default' && (
        <div
          className={CN('flex flex-col', {
            'w-[250px]': isSidebar,
            'w-[300px]': !isSidebar,
          })}>
          <img src={BdlLogo} alt='Logo' />
        </div>
      )}
      {appearance === 'mini' && (
        <div className='flex flex-col w-[33px]'>
          <img src={BdlLogoMini} alt='Logo' />
        </div>
      )}
    </>
  )
}

Logo.defaultProps = {
  className: undefined,
  appearance: 'default',
  isSidebar: false,
}

export default Logo
