/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { JsonLoader } from 'components/atoms/JsonLoader'

const Table = ({
  columns,
  data,
  selectedTableRows,
  setTableVariables,
  tableVariables,
  totalRowCount,
  isLoading,
  isManifesting,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        selectedRowIds: selectedTableRows || [],
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((cols) => [
    //     {
    //       id: 'selection',
    //       customWidth: 32,
    //       Cell: ({ row }) => <div {...row.getToggleRowSelectedProps()} />,
    //     },
    //     ...cols,
    //   ])
    // },
  )

  /** Pagination States */
  const [paginationVariables, setPaginationVariables] = useState({
    start: 0,
    end: 0,
  })
  const [pageNumber, setPageNumber] = useState(1)

  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      start:
        Number(tableVariables.take) * Number(pageNumber) -
        Number(tableVariables.take) +
        1,
      end:
        Number(tableVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(tableVariables.take) * Number(pageNumber),
    })
  }, [tableVariables, totalRowCount, pageNumber])

  /** Pagination Functions */
  useEffect(() => {
    setTableVariables({
      ...tableVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(tableVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])

  const slicedPageData = page

  return (
    <>
      <div className='styled-scroll h-[calc(100%-81px)] relative overflow-y-auto rounded-t-md border border-N-200'>
        {(isLoading || isManifesting) && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] bg-opacity-10 cursor-not-allowed'>
            <div className='flex flex-col w-full h-full bg-white justify-center items-center'>
              <JsonLoader size='xxs' />
              {isManifesting && <span>Manifesting Orders...</span>}
            </div>
          </div>
        )}

        <table className='w-full overflow-y-auto' {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={CN(
                      'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left pl-4 z-[1]',
                      {
                        'bg-N-200': column.isSorted,
                      },
                    )}
                    style={{ height: 45 }}
                    width={column.customWidth}>
                    <div
                      className='inline-flex items-center'
                      style={{ minHeight: 20 }}>
                      {column.render('Header')}
                      <span className='flex ml-1' style={{ width: 14 }}>
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <i className='ri-arrow-up-s-line' />
                          ) : (
                            <i className='ri-arrow-down-s-line' />
                          ))}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {slicedPageData.map((row) => {
              prepareRow(row)
              return (
                <tr
                  className='cursor-pointer h-11 hover:bg-N-200'
                  {...row.getRowProps()}
                  onClick={() => {
                    toggleAllRowsSelected(false)
                    row.toggleRowSelected()
                  }}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={CN(
                        'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-4',
                        {
                          'bg-N-300 border-b border-b-N-400': cell.isSelected,
                        },
                      )}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className='flex items-center justify-between px-3 py-[10px] border border-N-200 rounded-b-md'>
        <div className='flex items-center'>
          <span className='text-sm text-N-700'>Show rows per page</span>
          <select
            className='w-20 ml-2 border rounded-md outline-none border-N-200 h-7'
            value={tableVariables.take}
            onChange={(e) => {
              setPageNumber(1)
              setTableVariables({ ...tableVariables, take: e.target.value })
            }}>
            {[10, 20, 30, 50].map((pageSizeValue) => (
              <option key={pageSizeValue} value={pageSizeValue}>
                {pageSizeValue}
              </option>
            ))}
          </select>
        </div>

        <div className='flex'>
          <div className='flex items-center text-sm text-N-700 mr-9'>
            <p>
              {paginationVariables.start} - {paginationVariables.end} of{' '}
              {totalRowCount}
            </p>
          </div>

          <Button
            className='mr-8'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber - 1)
            }}
            disabled={pageNumber <= 1}>
            <i className='ri-arrow-left-s-line' />
          </Button>

          <Button
            className='mr-2'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
            disabled={
              Number(pageNumber) * Number(tableVariables.take) >=
              Number(totalRowCount)
            }>
            <i className='ri-arrow-right-s-line' />
          </Button>
        </div>
      </div>
    </>
  )
}

Table.propTypes = {}

export default Table
