/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'

import './DatePicker.scss'

export interface DatePickerProps {
  allowEdit?: boolean
  disableWeekends?: boolean
  enabled?: boolean
  format?: string | undefined
  minDate?: any
  onChange?: (e: any) => void | undefined
  value?: any
  width?: string | undefined
  isBulkFilter?: boolean
  isClearIcon?: boolean
  onClearClick?: () => void | undefined
  [x: string]: any
}

export const DatePicker: FC<DatePickerProps> = ({
  allowEdit,
  disableWeekends,
  enabled,
  format,
  minDate,
  value,
  width,
  isBulkFilter,
  isClearIcon,
  onChange,
  onClearClick,
  ...restProps
}: DatePickerProps) => {
  /** disable weekends */
  const disabledDate = (args: any) => {
    if (disableWeekends) {
      if (args.date.getDay() === 0 || args.date.getDay() === 6) {
        // set 'true' to disable the weekends
        args.isDisabled = true
      }
    }
  }
  return (
    <div
      className={CN({
        'consign-date-picker': !isBulkFilter,
        'consign-date-picker-bulk': isBulkFilter,
        'flex items-center relative': isClearIcon,
      })}>
      <DatePickerComponent
        enabled={enabled}
        value={value}
        width={width}
        format={format}
        onChange={onChange}
        cssClass={'e-calendar-yellow'}
        allowEdit={allowEdit}
        renderDayCell={disabledDate}
        min={minDate}
        {...restProps}
      />

      {isClearIcon && value && (
        <div className='absolute right-[32px] h-full flex justify-center items-center'>
          <i
            className='ri-close-line text-[18px] pt-0.5 text-N-500 cursor-pointer'
            onClick={onClearClick}
          />
        </div>
      )}
    </div>
  )
}

DatePicker.defaultProps = {
  allowEdit: true,
  disableWeekends: false,
  enabled: true,
  format: 'dd/MM/yyyy',
  minDate: undefined,
  onChange: undefined,
  onClearClick: undefined,
  value: undefined,
  width: undefined,
  isBulkFilter: false,
  isClearIcon: false,
}

export default DatePicker
