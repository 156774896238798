import { FC } from 'react'
import CN from 'classnames'

export interface OrderConsignmentStateBadgeProps {
  appearance?:
    | string
    | 'Confirmed'
    | 'Partial-consigned'
    | 'Closed'
    | 'Cancelled'
    | 'Backorder'
    | 'Open'
    | 'Tobeconsigned'
    | 'Exception'
    | 'Onhold'
    | undefined
  className?: string | undefined
  labelValue?: string | undefined
}

export const OrderConsignmentStateBadge: FC<
  OrderConsignmentStateBadgeProps
> = ({
  appearance,
  className,
  labelValue,
}: OrderConsignmentStateBadgeProps) => {
  const badgeClassnames = CN(
    'flex items-center justify-center w-fit rounded',
    {
      'bg-G-100 text-G-700': appearance === 'Confirmed',
      'bg-[#64748B] bg-opacity-[15%] text-[#64748B]': appearance === 'Closed',
      'bg-N-200 text-S-900': appearance === 'Onhold',
      'bg-P-50 text-P-600': appearance === 'Partial-consigned',
      'bg-[#D9D9D9] text-N-700': appearance === 'Cancelled',
      'bg-[#0066FF] bg-opacity-[15%] text-[#0066FF]':
        appearance === 'Backorder',
      'bg-[#CF46FF] bg-opacity-[15%] text-[#CF46FF]': appearance === 'Open',
      'bg-[#46B1FF] bg-opacity-[15%] text-[#46B1FF]':
        appearance === 'Tobeconsigned',
      'bg-[#E5A80F] bg-opacity-[20%] text-[#E5A80F]':
        appearance === 'Exception',
    },
    className,
  )

  return (
    <div className={badgeClassnames}>
      <p className='flex items-center justify-center text-sm font-semibold px-2 py-1'>
        {labelValue === 'Backorder'
          ? 'Back Order'
          : labelValue === 'Tobeconsigned'
          ? 'To be Consigned'
          : labelValue === 'Onhold'
          ? 'On Hold'
          : labelValue}
      </p>
    </div>
  )
}

OrderConsignmentStateBadge.defaultProps = {
  className: undefined,
  appearance: 'Confirmed',
  labelValue: undefined,
}

export default OrderConsignmentStateBadge
