import { FC } from 'react'

import { FilterChip } from 'components/atoms/FilterChip'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export interface FilterChipSectionProps {
  [x: string]: any
}

/** This is FilterChipSection */

export const FilterChipSection: FC<FilterChipSectionProps> = ({
  filterSectionKey,
  partnerOrderVariables,
  startDateValue,
  endDateValue,
  setPartnerOrderVariables,
  setPageNumber,
}: FilterChipSectionProps) => {
  const resetStatusFilter = (element: string) => {
    setPageNumber(1)
    partnerOrderVariables.status?.length === 1
      ? setPartnerOrderVariables({
          ...partnerOrderVariables,
          status: ['ALL'],
        })
      : setPartnerOrderVariables({
          ...partnerOrderVariables,
          status: partnerOrderVariables?.status?.filter(
            (status: string) => status !== element,
          ),
        })
  }

  const resetInternationalFilter = () => {
    setPageNumber(1)
    setPartnerOrderVariables({
      ...partnerOrderVariables,
      isInternational: false,
    })
  }

  const resetPartnerFilter = () => {
    setPageNumber(1)
    setPartnerOrderVariables({
      ...partnerOrderVariables,
      partner: '',
    })
  }

  const resetDateFilter = () => {
    setPageNumber(1)
    setPartnerOrderVariables({
      ...partnerOrderVariables,
      from:
        startDateValue.getFullYear() +
        '-' +
        ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
        '-' +
        startDateValue.getDate(),
      to:
        endDateValue.getFullYear() +
        '-' +
        ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
        '-' +
        endDateValue.getDate(),
    })
  }

  return (
    <div
      className='flex w-full items-center justify-start'
      key={filterSectionKey}>
      {partnerOrderVariables.status[0] !== 'ALL' &&
        partnerOrderVariables.status !== '' &&
        partnerOrderVariables.status?.map((element: any) => (
          <FilterChip
            labelValue='Status'
            filterValue={capitalizeFirstLetter(
              element === 'TOBECONSIGNED'
                ? 'To be consigned'
                : element === 'BACKORDER'
                ? 'Back Order'
                : element === 'ONHOLD'
                ? 'On Hold'
                : element,
            )}
            onClick={() => {
              resetStatusFilter(element)
            }}
          />
        ))}
      {partnerOrderVariables.partner.value !== undefined && (
        <FilterChip
          labelValue='Partner'
          filterValue={partnerOrderVariables.partner.label}
          onClick={() => {
            resetPartnerFilter()
          }}
        />
      )}
      {!(
        partnerOrderVariables.from.toString() ===
          (
            startDateValue.getFullYear() +
            '-' +
            ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
            '-' +
            startDateValue.getDate()
          ).toString() &&
        partnerOrderVariables.to.toString() ===
          (
            endDateValue.getFullYear() +
            '-' +
            ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
            '-' +
            endDateValue.getDate()
          ).toString()
      ) && (
        <FilterChip
          labelValue='Date'
          filterValue={`${partnerOrderVariables.from} to ${partnerOrderVariables.to}`}
          onClick={() => {
            resetDateFilter()
          }}
        />
      )}
      {partnerOrderVariables.isInternational && (
        <FilterChip
          labelValue='International Orders'
          filterValue={partnerOrderVariables.isInternational && 'True'}
          onClick={() => {
            resetInternationalFilter()
          }}
        />
      )}
    </div>
  )
}

FilterChipSection.defaultProps = {}

export default FilterChipSection
