import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getPartnerIntegrationByIdInterface {
  id?: string
  withSftpIntegration?: boolean
}

export const useGetPartnerIntegrationById = () => {
  const mutate = useMutation(
    ({ id, withSftpIntegration }: getPartnerIntegrationByIdInterface) => {
      return get(
        `/Partner/${id}/Integration?skip=0&take=99&withSftpIntegration=${
          withSftpIntegration ? 'true' : 'false'
        }`,
      )
    },
  )

  return mutate
}

export default useGetPartnerIntegrationById
