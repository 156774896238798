import { FC } from 'react'

import { ShippingLineSelection } from 'components/molecules/ShippingLineSelection'

export interface TabAllProps {
  className?: string | undefined
  shipment: any
  selectedOrderId?: any
  searchForSavedQuotes: any
  notify?: any
  callOrderShipmentsById?: any
  setMainLoadingState?: any
  isAPXDisable?: boolean
}

export const TabAll: FC<TabAllProps> = ({
  shipment,
  selectedOrderId,
  searchForSavedQuotes,
  notify,
  callOrderShipmentsById,
  setMainLoadingState,
  isAPXDisable,
}: TabAllProps) => {
  const getCharges = (quote: any) => {
    let chargeTableModel: any = []
    if (quote.type === 'AUSPOST') {
      chargeTableModel = [
        {
          type: 'Bundle Price',
          price: quote.bundlePrice,
        },
        {
          type: 'Bundle Price Ex Gst',
          price: quote.bundlePriceExGst,
        },
        {
          type: 'Bundle Price Gst',
          price: quote.bundlePriceGst,
        },
        {
          type: 'Calculated Price',
          price: quote.calculatedPrice,
        },
        {
          type: 'Calculated Price Ex Gst',
          price: quote.calculatedPriceExGst,
        },
        {
          type: 'Calculated Price Gst',
          price: quote.calculatedPriceExGst,
        },
      ]
    } else if (quote.type === 'AUSPOST_INTERNATIONAL') {
      chargeTableModel = [
        {
          type: 'Calculated Price',
          price: quote.calculatedPrice,
        },
        {
          type: 'Calculated Price Ex Gst',
          price: quote.calculatedPriceExGst,
        },
        {
          type: 'Calculated Price Gst',
          price: quote.calculatedPriceExGst,
        },
      ]
    } else if (quote.type === 'STARSHIPIT') {
      chargeTableModel = [
        {
          type: 'Carrier Code',
          price: quote.carrierCode,
        },
        {
          type: 'Carrier Name',
          price: quote.carrierName,
        },
        {
          type: 'Service Name',
          price: quote.serviceName,
        },
        {
          type: 'Service Code',
          price: quote.serviceCode,
        },
        {
          type: 'Total Price',
          price: quote.total,
        },
      ]
    } else if (quote.type === 'DIRECT') {
      chargeTableModel = [
        {
          type: 'Standard Charge',
          price: quote.standardCharge,
        },
        {
          type: 'Total Freight Charge',
          price: quote.totalFreightCharge,
        },
        {
          type: 'Fuel Levy',
          price: quote.fuelLevy,
        },
        {
          type: 'Fuel Levy Charge',
          price: quote.fuelLevyCharge,
        },
        {
          type: 'On Forward Charge',
          price: quote.onForwardCharge,
        },
      ]
    } else if (quote.type === 'SMART/SEND') {
      chargeTableModel = [
        {
          type: 'Discount',
          price: quote.discount,
        },
        {
          type: 'Total Discount',
          price: quote.totalDiscount,
        },
        {
          type: 'Total Transport Assurance',
          price: quote.totalTransportAssurance,
        },
        {
          type: 'Total GST',
          price: quote.totalGST,
        },
        {
          type: 'Total Price',
          price: quote.totalPrice,
        },
      ]
    } else if (quote.type === 'MACHSHIP') {
      chargeTableModel = [
        {
          type: 'Base Total',
          price: quote.baseTotal,
        },
        {
          type: 'Fuel Level',
          price: quote.fuelLevy,
        },
        {
          type: 'GST Price',
          price: quote.gstPrice,
        },
        {
          type: 'Total Price',
          price: quote.total,
        },
      ]
    } else if (quote.type === 'ALLIED') {
      chargeTableModel = [
        {
          type: 'Job Charge',
          price: quote.jobCharge,
        },
        {
          type: 'Total Charge',
          price: quote.totalCharge,
        },
      ]
    }
    return chargeTableModel
  }

  return (
    <div className='w-full pt-6 border-t border-t-N-200'>
      {shipment.shipmentConsignmentQuotes &&
        shipment.shipmentConsignmentQuotes.length > 0 &&
        shipment.shipmentConsignmentQuotes
          ?.filter((item: any) => {
            if (item.type === 'STARSHIPIT') {
              return item.cost > 0
            } else {
              return true
            }
          })
          ?.map((quote: any, index: any) => {
            const obj = {
              type: quote.type,
              productName: quote.productName,
              shipmentId: shipment.id,
              productId: quote.productId,
              cost: quote.cost,
              carrierCode: quote?.carrierCode,
              originCharges: getCharges(quote),
              carrierService: quote?.carrierService,
            }
            return (
              <ShippingLineSelection
                key={index}
                isViewOnly={
                  shipment?.status === 'CLOSED' ||
                  shipment?.status === 'CANCELLED'
                }
                ShippingLineObject={obj}
                selectedOrderId={selectedOrderId}
                dateWanted={shipment?.dateWanted}
                notify={notify}
                callOrderShipmentsById={callOrderShipmentsById}
                setMainLoadingState={setMainLoadingState}
                isAPXDisable={isAPXDisable}
              />
            )
          })}
      {shipment.shipmentConsignmentQuotes &&
        searchForSavedQuotes(shipment.shipmentConsignmentQuotes, 'ALL')}
    </div>
  )
}

TabAll.defaultProps = {
  className: undefined,
}

export default TabAll
