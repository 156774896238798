import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetOrderCutoffTableInterface {
  take?: number
  skip?: number
  name?: string
}

export const useGetOrderCutoffTable = () => {
  const mutate = useMutation(
    ({ take, skip, name }: GetOrderCutoffTableInterface) => {
      return get(
        `/PartnerCutoffTimeList?take=${take}&skip=${skip}&search=${name}`,
      )
    },
  )

  return mutate
}

export default useGetOrderCutoffTable
