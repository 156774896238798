import { FC } from 'react'

import { ShippingLineSelection } from 'components/molecules/ShippingLineSelection'

export interface TabStarShipItProps {
  className?: string | undefined
  shipment: any
  searchForSavedQuotes: any
  selectedOrderId?: any
  notify?: any
  callOrderShipmentsById?: any
  setMainLoadingState?: any
}

export const TabStarShipIt: FC<TabStarShipItProps> = ({
  className,
  shipment,
  searchForSavedQuotes,
  selectedOrderId,
  notify,
  callOrderShipmentsById,
  setMainLoadingState,
}: TabStarShipItProps) => {
  return (
    <div className='w-full pt-6 border-t border-t-N-200'>
      {shipment.shipmentConsignmentQuotes &&
        shipment.shipmentConsignmentQuotes.length > 0 &&
        shipment.shipmentConsignmentQuotes
          ?.filter((item: any) => item?.cost > 0)
          ?.map((quote: any, index: any) => {
            const obj = {
              type: quote.type,
              productName: quote.productName,
              shipmentId: shipment.id,
              productId: quote.productId,
              cost: quote.cost,
              carrierCode: quote?.carrierCode,
              originCharges: [
                {
                  type: 'Carrier Code',
                  price: quote.carrierCode,
                },
                {
                  type: 'Carrier Name',
                  price: quote.carrierName,
                },
                {
                  type: 'Service Name',
                  price: quote.serviceName,
                },
                {
                  type: 'Service Code',
                  price: quote.serviceCode,
                },
                {
                  type: 'Total Price',
                  price: quote.total,
                },
              ],
              SameDayPickup: quote.sameDayPickupAvailable,
              SameDayPickupCutOffTime: quote.sameDayPickupCutOffTime,
              TransitDescription: quote.transitDescription,
            }
            if (quote.type === 'STARSHIPIT') {
              return (
                <ShippingLineSelection
                  key={index}
                  isViewOnly={
                    shipment?.status === 'CLOSED' ||
                    shipment?.status === 'CANCELLED'
                  }
                  ShippingLineObject={obj}
                  selectedOrderId={selectedOrderId}
                  dateWanted={shipment?.dateWanted}
                  notify={notify}
                  callOrderShipmentsById={callOrderShipmentsById}
                  setMainLoadingState={setMainLoadingState}
                />
              )
            }
          })}
      {shipment.shipmentConsignmentQuotes &&
        searchForSavedQuotes(shipment.shipmentConsignmentQuotes, 'STARSHIPIT')}
    </div>
  )
}

TabStarShipIt.defaultProps = {
  className: undefined,
}

export default TabStarShipIt
